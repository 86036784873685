#toggle-theme {
  .switch {
    position: relative;
  }

  .switch-input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    z-index: 1;
  }

  .switch-label {
    display: block;
    position: relative;
    width: 72px;
    height: 36px;
    background-color: var(--mat-fab-foreground-color);
    border-radius: 25px;
    transition: 0.4s;
  }

  .switch-label::before {
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 1px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 2px var(--mat-fab-foreground-color) solid;
    background-color: var(--mdc-fab-container-color);
    transition: 0.4s;
    content: "";
  }

  .switch-input:checked+.switch-label {
    background-color: var(--mat-fab-foreground-color);
  }

  .switch-input:checked+.switch-label::before {
    left: calc(100% - 36px);
    border-color: var(--mdc-fab-container-color);
    background-color: var(--mdc-fab-container-color);
  }

  .switch .moon-icon,
  .switch .sun-icon {
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(15%, -50%);
    z-index: 2;
  }


  .switch .moon-icon {
    left: 4px;
  }

  .switch .sun-icon {
    right: 4px;
  }

  @media (max-width: 850px) {
    #toggle-theme {
      max-width: 100% ;
    }
  }

}
