$animationDuration: 3s;
$amount: 6;
$particleRadius: 4vmin;

.background span {
  width: $particleRadius * 2;
  height: $particleRadius * 2;
  border-radius: $particleRadius;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 0.8);
  animation-iteration-count: infinite;
  animation-duration: $animationDuration;
  top: calc(50% - #{$particleRadius});
  left: 50%;
  transform-origin: ($particleRadius * -1) center;

  $colors: ($boiler-success, $boiler-secondary, $boiler-primary);

  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      background: nth($colors, random(length($colors)));
      animation-delay: calc($i / $amount) * $animationDuration - 1;
      opacity: 0;
    }
  }
}

@keyframes move {
  0% {
    transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
  }

  30% {
    opacity: 1;
  }

  100% {
    z-index: 10;
    transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
  }
}
