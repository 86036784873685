:root {
  --primary-0: #010202;
  --primary-10: #bbdefb;
  --primary-20: #90caf9;
  --primary-25: #64b5f6;
  --primary-30: #42a5f5;
  --primary-35: #2196f3;
  --primary-40: #1e88e5;
  --primary-50: #1976d2;
  --primary-60: #1565c0;
  --primary-70: #0d47a1;
  --primary-80: #448aff;
  --primary-90: #2979ff;
  --primary-95: #2962ff;
  --primary-98: #304ffe;
  --primary-99: #6200ea;
  --primary-100: #304ffe;

  --p-secondary-0: #1f2829;
  --p-secondary-10: #b2ebf2;
  --p-secondary-20: #80deea;
  --p-secondary-25: #4dd0e1;
  --p-secondary-30: #26c6da;
  --p-secondary-35: #00bcd4;
  --p-secondary-40: #00acc1;
  --p-secondary-50: #0097a7;
  --p-secondary-60: #00838f;
  --p-secondary-70: #006064;
  --p-secondary-80: #84ffff;
  --p-secondary-90: #18ffff;
  --p-secondary-95: #00e5ff;
  --p-secondary-98: #00b8d4;
  --p-secondary-99: #00b8d4;
  --p-secondary-100: #00b8d4;

  --p-neutral-0: #000000;
  --p-neutral-10: #191c1c;
  --p-neutral-20: #2e3131;
  --p-neutral-25: #393c3c;
  --p-neutral-30: #454748;
  --p-neutral-35: #505353;
  --p-neutral-40: #5c5f5f;
  --p-neutral-50: #757778;
  --p-neutral-60: #8f9191;
  --p-neutral-70: #aaabac;
  --p-neutral-80: #c5c7c7;
  --p-neutral-90: #e1e3e3;
  --p-neutral-95: #f0f1f1;
  --p-neutral-98: #f9f9fa;
  --p-neutral-99: #fbfcfc;
  --p-neutral-100: #ffffff;

  --p-neutral-variant-0: #000000;
  --p-neutral-variant-10: #161d1e;
  --p-neutral-variant-20: #2b3233;
  --p-neutral-variant-25: #363d3e;
  --p-neutral-variant-30: #414849;
  --p-neutral-variant-35: #4d5455;
  --p-neutral-variant-40: #586061;
  --p-neutral-variant-50: #71787a;
  --p-neutral-variant-60: #8b9293;
  --p-neutral-variant-70: #a5acae;
  --p-neutral-variant-80: #c1c8c9;
  --p-neutral-variant-90: #dde4e5;
  --p-neutral-variant-95: #ebf2f3;
  --p-neutral-variant-98: #f4fbfc;
  --p-neutral-variant-99: #f7fdff;
  --p-neutral-variant-100: #ffffff;

  --error-0: #000000;
  --error-10: #410002;
  --error-20: #690005;
  --error-25: #7e0007;
  --error-30: #93000a;
  --error-35: #a80710;
  --error-40: #ba1a1a;
  --error-50: #de3730;
  --error-60: #ff5449;
  --error-70: #ff897d;
  --error-80: #ffb4ab;
  --error-90: #ffdad6;
  --error-95: #ffedea;
  --error-98: #fff8f7;
  --error-99: #fffbff;
  --error-100: #ffffff;

  --tertiary-0: #e0f7fa;
  --tertiary-10: #b2ebf2;
  --tertiary-20: #80deea;
  --tertiary-25: #4dd0e1;
  --tertiary-30: #26c6da;
  --tertiary-35: #00bcd4;
  --tertiary-40: #00acc1;
  --tertiary-50: #0097a7;
  --tertiary-60: #00838f;
  --tertiary-70: #006064;
  --tertiary-80: #84ffff;
  --tertiary-90: #18ffff;
  --tertiary-95: #00e5ff;
  --tertiary-98: #00b8d4;
  --tertiary-99: #00b8d4;
  --tertiary-100: #00b8d4;
}

html {
  padding: 0;
  margin: 0;
}
