@use '@angular/material' as mat;

$back-to-top: mat.define-theme();

.back-to-top {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  transition: all 0.2s ease-in-out, transform 0.25s cubic-bezier(0.75, -0.5, 0, 1.75), visibility 0.3s linear, opacity 0.3s ease-in-out;
}

.back-to-top:hover {
  box-shadow: 0 0.3rem 0.75rem rgba(0, 0, 0, 0.3) !important;
  text-decoration: none;
}

@media (min-width: 1200px) {
  .back-to-top {
    bottom: 32px;
    right: 32px;
  }
}

@media (min-width: 768px) {
  .back-to-top {
    width: 56px;
    height: 56px;
  }
}

@mixin theme($back-to-top) {
  .back-to-top {
    background: mat.get-theme-color($back-to-top, secondary, 70);
  }
}

:root {
  @include theme($back-to-top);
}
