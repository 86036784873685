@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();

// Note: Color palettes are generated from primary: #42a5f5
$_palettes: (
  primary: (0: #000000,
    10: #001d34,
    20: #003355,
    25: #003e66,
    30: #004a78,
    35: #00568b,
    40: #00629d,
    50: #007cc5,
    60: #2c96e5,
    70: #57b1ff,
    80: #99cbff,
    90: #cfe5ff,
    95: #e8f1ff,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  secondary: (0: #000000,
    10: #0e1d2a,
    20: #243240,
    25: #2f3d4b,
    30: #3a4857,
    35: #465463,
    40: #526070,
    50: #6a7889,
    60: #8492a3,
    70: #9eadbe,
    80: #b9c8da,
    90: #d5e4f7,
    95: #e8f1ff,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  tertiary: (0: #000000,
    10: #231532,
    20: #392a49,
    25: #453554,
    30: #504060,
    35: #5d4b6d,
    40: #695779,
    50: #827093,
    60: #9d89ae,
    70: #b8a3c9,
    80: #d4bee6,
    90: #f0dbff,
    95: #f9ecff,
    98: #fff7fe,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (0: #000000,
    10: #1a1c1e,
    20: #2f3033,
    25: #3a3b3e,
    30: #45474a,
    35: #515255,
    40: #5d5e61,
    50: #76777a,
    60: #909094,
    70: #aaabae,
    80: #c6c6c9,
    90: #e2e2e5,
    95: #f1f0f4,
    98: #f9f9fc,
    99: #fcfcff,
    100: #ffffff,
    4: #0c0e11,
    6: #121316,
    12: #1e2022,
    17: #282a2d,
    22: #333538,
    24: #38393c,
    87: #dadadd,
    92: #e8e8eb,
    94: #eeedf1,
    96: #f3f3f7,
  ),
  neutral-variant: (0: #000000,
    10: #171c22,
    20: #2c3137,
    25: #373c42,
    30: #42474e,
    35: #4e535a,
    40: #5a5f66,
    50: #72777f,
    60: #8c9199,
    70: #a7abb3,
    80: #c2c7cf,
    90: #dee3eb,
    95: #edf1f9,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  error: (0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((color: (theme-type: light,
        primary: $_primary,
        tertiary: $_tertiary,
      ),
    ));

$dark-theme: mat.define-theme((color: (theme-type: dark,
        primary: $_primary,
        tertiary: $_tertiary,
      ),
    ));

html {
  .dark-theme {
    @include mat.all-component-themes($dark-theme);
    @include mat.color-variants-backwards-compatibility($dark-theme);
  }

  .light-theme {
    @include mat.all-component-themes($light-theme);
    @include mat.color-variants-backwards-compatibility($light-theme);
  }
}
