.mdc-snackbar__surface {
  display: none;
}

.mdc-snackbar__label {
  display: none;
}

.snackbar-success {
  .mdc-snackbar__surface {
    display: block;
    background-color: $boiler-success !important;
  }

  .mdc-snackbar__label {
    display: block;
    color: $white !important;
  }
}

.snackbar-warn {
  .mdc-snackbar__surface {
    display: block;
    background-color: $orange-warning !important;
  }

  .mdc-snackbar__label {
    display: block;
    color: $white !important;
  }
}

.snackbar-error {
  .mdc-snackbar__surface {
    display: block;
    background-color: red !important;
  }

  .mdc-snackbar__label {
    display: block;
    color: $white !important;
  }
}
