#sidebar {
  position: relative;
  background-color: var(--mat-select-panel-background-color);
  padding: 15px 0;
  width: 300px;
  min-height: calc(100vh - 30px);
  transition: all 0.5s;

  .toggle-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .logo {
      width: calc(100% - 112px);
      height: auto;
      margin: 0 16px;
      object-fit: contain;
      transition: all 0.5s;
    }

    .toggle-container {
      flex-grow: 0;
      margin: 10px 20px;
    }
  }

  .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 28px;
    height: 28px;
    max-width: 100%;
    margin-left: 5px;
    overflow: hidden;
  }

  .icon {
    pointer-events: all;
  }

  &.collapsed {
    max-width: 110px;
    transition: all 0.5s;

    .title {
      display: none;
    }

    .logo {
      width: 0 !important;
    }

    .toggle-container {
      margin: 0 auto;
    }
  }

  .menu {
    margin-top: 32px;

    .menu-module {
      position: relative;
      cursor: pointer;
      padding-top: 48px;
      padding-right: 25px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 0 25px 25px 0;
        pointer-events: none;
        z-index: 0;
      }

      &:not(.dashboard)::after {
        content: "\25BA";
        position: absolute;
        right: 30px;
        pointer-events: none;
        top: 15px;
        transition: all 0.5s;
        font-size: small;
      }

      &.active {
        background-color: var(--mdc-filled-text-field-container-color);

        &::after {
          transform: rotate(90deg);
        }
      }

      >.menu-module-link {
        pointer-events: none;
        z-index: 2;
        position: absolute;
        top: 10px;
        left: 25px;
        display: flex;
      }

      &.dashboard {
        >.menu-module-link {
          pointer-events: all;
        }

        &.active {
          border-radius: 0 25px 25px 0;
          margin-right: 24px;
          background-color: var(--mat-stepper-header-selected-state-icon-foreground-color);
        }
      }

      &:not(.active) {
        >.children {
          opacity: 0 !important;
          max-height: 0 !important;
        }
      }

      >.children {
        overflow: hidden;
        height: auto;
        transition: all 0.5s;
        opacity: 1;

        >.menu-link {
          display: flex;

          >a {

            text-decoration: none !important;
          }

          padding: 10px 25px 10px 45px;
          border-radius: 0 25px 25px 0;

          &:hover {
            background-color: rgba(0, 0, 0, 0.3);
          }

          &.active {
            background-color: var(--mdc-fab-container-color);
            border: 1px solid var(--mat-fab-foreground-color);
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  #sidebar {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    min-height: 80px;
    padding-right: 0 !important;

    &.collapsed {
      max-width: 100% !important;
      width: 100% !important;
      height: 80px;
      min-height: 80px;

      .menu-module{
        display: none;
      }
    }

    .toggle-row {
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .logo {
        width: 50% !important;
      }

      .toggle-container {
        margin: 0;

      }
    }
  }
}
