// Import Design Tokens Italia
// @import '../../node_modules/design-tokens-italia/scss/variables';

// Shadows
$enable-shadows: true;

// Spacer
$spacer: 1rem !default;

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;

// Vertical base space
$v-gap: 8px !default;

// Responsive gutter widths
$grid-gutter-widths: (
  xs: 12px,
  sm: 12px,
  md: 20px,
  lg: 24px,
  xl: 24px,
  xxl: 28px,
) !default;

$aspect-ratios: (
  '1x1': 100%,
  '3x2': calc(2 / 3 * 100%),
  '4x3': calc(3 / 4 * 100%),
  '16x9': calc(9 / 16 * 100%),
  '21x9': calc(9 / 21 * 100%),
);

//ratio

// Text
$font-family-serif: 'Lora', Georgia, serif !default;
$font-family-sans-serif: 'Titillium Web', Geneva, Tahoma, sans-serif !default;
$font-family-monospace: 'Roboto Mono', monospace !default;
$text-color: $gray-700 !default;
$text-muted: $color-text-muted !default;
$letter-spacing-base: 0;
$line-height-base: 1.5;
$mark-bg: $analogue-2-a1;

// Paragraphs
$paragraph-margin-bottom: 1rem !default;
$small-font-size: 0.875rem !default;

// Links
$link-decoration: underline;

// Focus
$focus-outline-color: $orange !default;

//skiplinks
$skiplinks-color: $primary !default;
$skiplinks-bg: $color-background-primary-lighter !default;

// Navbar
$navbar-brand-font-size: 1rem !default;
$navbar-toggler-border-radius: 0 !default;
$navbar-dark-toggler-icon-bg: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkuMnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMiAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSIxMDI0dXAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSItLWhvbWUtLS1wYXJhbGxheC0tLW1vYmlsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0LjAwMDAwMCwgLTIwLjAwMDAwMCkiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8ZyBpZD0iLW5ldHdvcmstc2xpbS1oZWFkZXIiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTI0LDI0IEwzNiwyNCBMMzYsMjYgTDI0LDI2IEwyNCwyNCBaIE0yNCwyMCBMMzIsMjAgTDMyLDIyIEwyNCwyMiBMMjQsMjAgWiBNMjQsMjggTDMyLDI4IEwzMiwzMCBMMjQsMzAgTDI0LDI4IFoiIGlkPSJpY29uLXNtYWxsLWJ1cmdlciI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') !default;
$navbar-dark-toggler-border-color: transparent !default;

// Buttons
$btn-border-width: 0;
$btn-text-transform: uppercase;
$btn-letter-spacing: normal;
$btn-font-weight: 600 !default;
$btn-font-size-sm: 1rem !default;
$btn-font-size-xs: 0.875rem !default;
$btn-font-size-lg: 1.125rem !default;

// Input
$input-btn-line-height: 1.5 !default;
$input-btn-line-height-sm: 1.5rem !default;
$input-btn-line-height-xs: 1rem !default;
$input-btn-line-height-lg: 1.75rem !default;
$input-disabled-bg: $gray-disabled !default;
$input-spacing-x: 0.5rem;
$input-spacing-y: 0.375rem;
$input-border-width: 0;
$input-border-radius: 0;
$input-group-addon-bg: $white !default;
$input-height: 2.5rem;
$form-group-margin-bottom: 3rem;
$form-group-margin-top: 0;
$avatar-base-size: 8px;

// progress
$progress-height: 16px;
$progress-border-radius: 0;

// z-index
$zindex-zoom: 1080;
$zindex-growl: 1090;
$zindex-cookiebar: 1100;
$zoom-overlay-bg: $white !default;

// Border Radius - using pixels instead or rems as it has to be consistent across devices
$border-radius: 4px !default;
$border-radius-sm: 2px !default;
$border-radius-lg: 8px !default;
$radius-rounded: 40px;

// Link and button colors
$btn-primary-bg: $primary !default;
$btn-primary-border: $primary !default;
$link-color: $primary !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// Code
$code-color: $dark !default;
$yiq-contrasted-threshold: 120 !default;

// Breadcrumbs
$breadcrumb-padding: 0.5em;
$breadcrumb-bg: transparent !default;
$breadcrumb-link-font-weight: 600;
$breadcrumb-link-color: $color-text-secondary !default;
$breadcrumb-link-x-padding: 0.5em;
$breadcrumb-bg-dark: $neutral-1-a8 !default; // Missing in the UI kit
$breadcrumb-border-radius: 0;
$breadcrumb-link-color-dark: $white !default;
$breadcrumb-icon-color-dark: $analogue-2-b1 !default; // Missing in the UI kit

//Accordion
$card-cap-bg: transparent !default;
$card-spacer-y: 1rem !default;
$card-spacer-x: 2rem !default;
$card-border-radius: 0 !default;
$card-body-spacer: 1rem !default;

// Tooltips
$tooltip-max-width: 32em !default;
$tooltip-opacity: 1 !default;
$tooltip-padding-y: 1rem !default;
$tooltip-padding-x: 1rem !default;

//Modals
$modal-backdrop-opacity: 0.8 !default;
$modal-max-width: 32rem !default;
$modal-dialog-margin-y-sm-up: 1.5rem !default;
$modal-footer-border-width: 0 !default;
$modal-header-border-width: 0 !default;
$modal-header-padding: 1.5rem !default;
$modal-inner-padding: 1.5rem !default;

// Carousel
$carousel-bg: #444e57 !default; // TODO: not used, consider to remove
$carousel-padding: 3em !default;
$carousel-control-color: $secondary !default;
$carousel-control-width: auto !default;
$carousel-control-opacity: 1 !default;
$carousel-control-icon-width: 32px !default;

// Inputs
$input-border: $gray-secondary !default;
$input-label-color: $color-text-base !default; // UI kit
$input-color-placeholder: $color-text-muted !default; // UI Kit
$input-focus-border-color: $gray-secondary !default;

//List group
$list-group-item-padding-y: 1rem !default;
$list-group-action-color: $primary !default;
$list-group-disabled-cursor: default !default;

//Reset
$reset-border-width: 0 !default;
$reset-border-radius: 0 !default;
$reset-padding: 0 !default;

// Alert
$alert-padding-y: 1rem;
$alert-padding-x: 1rem;

// Blockquote
$blockquote-small-color: $gray-600 !default;
$blockquote-small-color-dark: $gray-400 !default;

// Skiplinks
$skiplink-padding-y: 0.5rem;
$skiplink-padding-x: 0.5rem;

// Dropdown
$dropdown-header-color: $dark !default;
$dropdown-link-hover-bg: #e6ecf2 !default;
$dropdown-link-color: $dark !default;
$dropdown-link-hover-color: $dark !default;
$dropdown-item-padding-x: 24px;
$dropdown-item-padding-y: 12px;
$dropdown-border-width: 0;
$dropdown-border-color: transparent !default;
$dropdown-box-shadow: 0 20px 30px 5px rgba(0, 0, 0, 0.05) !default;
$dropdown-box-shadow-vertical: 0 0 30px 5px rgba(0, 0, 0, 0.05) !default;

// Dropdown custom
$dropdown-custom-button-padding: 0 4px;
$dropdown-custom-button-color: $primary !default;
$dropdown-custom-button-background: transparent !default;
$dropdown-custom-button-caret-font-size: 0.5rem;
$dropdown-custom-button-caret-distance: $v-gap;
$dropdown-menu-animation-speed: 0.3s;
$dropdown-menu-vertical-shift: 16px;
$dropdown-menu-radius: 4px;
$dropdown-menu-notch-base-size: 6px;
$dropdown-menu-notch-position-x: 24px;
$dropdown-menu-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);

//Navigation
$navbar-bg-color: $primary !default;
$navigation-bg-color: $white !default;
$navigation-bg-color-desktop: $primary !default;

$navigation-h-padding: 24px;
$navigation-v-padding: 13px;
$navigation-sidebar-bg-color-mobile: rgba(0, 0, 0, 0.6) !default;
$navigation-close-button-size: 44px !default;
$navigation-close-button-text-color: $primary !default;
$navigation-close-button-text-size: 0.75rem;
$navigation-close-button-icon-size: 2rem;
$navigation-links-margin-top: 102px;
$navigation-link-color: $primary !default;
$navigation-link-color-desk: $white !default;
$navigation-link-active-bar-size: 3px;
$navigation-link-active-bar-color: $primary !default;
$navigation-link-active-bar-color-desk: $white !default;
$navigation-toggle-button-icon-color: $white !default;
$navigation-toggle-button-icon-size: 1.5rem;
$navigation-dropdown-icon-size: 0.75rem;
$navigation-dropdown-icon-line-height: 1rem;
$navigation-dropdown-icon-width: 1rem;
$navigation-disabled-item-opacity: 0.7 !default;
$navigation-disabled-item-mobile-opacity: 0.6 !default;
$navigation-hamburger-size: 24px;

// navigation dark theme (mobile)
$navigation-dark-bg-color: $primary !default; // missing in UI kit
$navigation-dark-text-color: $white !default; // missing in UI kit
$navigation-dark-bg-color: $primary-b1 !default; // missing in UI kit
$navigation-dark-separator-color: $primary-c5 !default; // missing in UI kit

// navigation light theme (desktop)
$navigation-light-bg-color: $white !default;
$navigation-light-text-color: $primary !default;
$navigation-light-separator-color: $neutral-1-a2 !default;
$navigation-light-megamenu-separator-color: $gray-border !default; // UI kit

//inline
$inline-menu-line-width: 2px;
$inline-menu-linklist-v-padding: 0.59em;

// Componente Base di Esempio
$componente-base-padding-x: 8px;
$componente-base-padding-y: 8px;

// Linklist
$link-list-font-size: 1rem;
$link-list-font-size-l: 1.125rem;
$link-list-line-height: 2rem;
$link-list-heading-size: 1.125rem;
$link-list-divider-height: 1px;
$link-list-divider-distance: 8px;
$link-list-h-pad: 24px;
$link-list-v-pad: 0.25em;
$link-list-paragraph-size: 0.75rem;
$link-list-left-icon-space: 8px;
$link-list-avatar-radius: 50px;
$link-list-avatar-margin: 8px;
$link-list-checkbox-icon-right: 35px;
$link-list-toggle-margin: 8px;
$link-list-icon-v-padding: 5px;
$link-list-v-padding-l: 0.45em;
$link-list-hover-color: $primary-a5 !default; // TODO: Not used, consider to remove it

// Megamenu
$megamenu-padding-top-desktop: $v-gap * 4;
$megamenu-column-gap: $v-gap * 3;
$megamenu-link-small-line-width: 65px;
$megamenu-link-small-line-margin-bottom: $v-gap;
$megamenu-heading-line-height: 1.2rem;
$megamenu-heading-font-weight: 600;
$megamenu-heading-bottom-margin: 24px;
$megamenu-heading-text-size: 1.125rem;
$megamenu-heading-margin-x: 28px;
$megamenu-linklist-link-line-height: inherit;
$megamenu-linklist-link-v-padding: 0.5em;
$megamenu-linklist-vertical-link-padding: 36px;
$megamenu-vertical-description-font-size: 1rem;

// Linklist footer megamenu
$link-list-footer-distance-bottom: 0.5rem;

// Pager
$pager-margin-bottom: 0.5rem;
$pager-item-size-mobile: 2.5rem; // 40px
$pager-item-size-tablet: 3rem; // 48px
$pager-item-border-radius: 4px;
$pager-item-margin-right: 5px;
$pager-item-current-color: $primary !default;
$pager-item-current-border: 1px solid $primary !default;
$pager-font-size: 1rem;
$pager-font-weight: 700;
$pager-font-color: $secondary !default;
$pager-hover-color: $primary !default;
$pager-icon-color: $primary !default;
$pager-icon-size: 1rem;
$pager-disabled-color: $gray-label-disabled !default;
$pager-jump-to-width: 4.5rem;
$pager-jump-to-color: $gray-secondary !default; // Kit UI
$pager-page-changer-padding: 12px;
$pager-page-changer-border-bottom: 1px solid $gray-secondary !default; // Kit UI

// Sidebar
$sidebar-heading-font-weight: 600;
$sidebar-heading-bottom-margin: 0.8rem;
$sidebar-heading-text-size: 1.15rem;
$sidebar-heading-margin-top: 4px;
$sidebar-heading-letter-spacing: 1px;
$sidebar-linklist-link-line-height: 1.5rem;
$sidebar-linklist-link-v-padding: 0.75rem;
$sidebar-link-small-line-width: 65px;
$sidebar-link-size: 1rem;
$sidebar-dropdown-icon-size: 1.5rem;
$sidebar-dropdown-line-selection-width: 2px;
$sidebar-dropdown-line-selection-color: $primary !default;
$sidebar-submenu-font-size: 1rem;
$sidebar-submenu-link-v-padding: 0.45em;
$sidebar-border-color: $gray-border !default;

//sidebar dark theme
$sidebar-dark-bg-color: $neutral-1-a8 !default; // Missing in the new UI kit
$sidebar-dark-text-color: $white !default;
$sidebar-dark-separator-color: rgba(229, 229, 229, 0.3) !default; // Missing in the new UI kit

// Navscroll
$navscroll-bg-color: $white !default;
$navscroll-toggler-color: $primary !default;
$navscroll-button-text-weight: 600;
$navscroll-top-box-shadow: 0 0px 30px 5px rgba(0, 0, 0, 0.05) !default;
$navscroll-bottom-box-shadow: 0 0px 30px 5px rgba(0, 0, 0, 0.05) !default;
$navscroll-primary-font-weight: 600;
$navscroll-font-size: 1rem;
$navscroll-selection-link-left: $sidebar-dropdown-line-selection-width solid $sidebar-dropdown-line-selection-color !default;
$navscroll-secondary-active-color: $color-text-primary-active !default;
$navscroll-links-padding: 0.55em;
$navscroll-bg-color-desk: $white !default;
$navscroll-line-color: $gray-border !default; // UI kit
$navscroll-backbutton-padding: $v-gap * 2 $v-gap * 3;
$navscroll-backbutton-weight: 600;
$navscroll-backbutton-margin-top: $v-gap * 3;

//navscroll dark theme mobile
$navscroll-dark-bg-color: $neutral-1-a8 !default; // Missing in the new UI kit
$navscroll-dark-text-color: $white !default;
$navscroll-dark-separator-color: rgba(229, 229, 229, 0.3) !default; // Missing in the new UI kit

//dialog
$dialog-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !default;
$modal-padding: $v-gap * 3;
$modal-margin: $v-gap * 6;
$modal-padding-close: $v-gap * 2;
$modal-heading-color: $color-text-base !default; // UI kit
$modal-icon-color: $primary !default;
$modal-icon-distance: $v-gap * 2;
$modal-alert-p-distance: 32px + $v-gap * 2;
$modal-heading-border: 1px solid $color-border-subtle !default;
$modal-sticky-bg: $white !default;
$modal-body-height: 50vh;
$modal-popconfirm-p-size-mobile: 0.875rem;
$modal-popconfirm-p-size: 1rem;
$modal-popconfirm-max-width: 300px;

// ##### HEADER #####

// Header Slim
$header-general-padding: $v-gap * 3;
$header-slim-height: 48px;
$header-slim-bg-color: $primary-a7 !default;
$header-slim-text-color: $white !default;
$header-slim-button-color: $primary-a9 !default;
$header-slim-button-hover-color: $primary-a8 !default;
$header-slim-brand-text-size: 0.875rem;
$header-slim-icon-size: 18px;
$header-slim-padding-desk: 3px $v-gap * 3;
$header-slim-dropdown-distance: 14px;
$header-slim-dropdown-distance-mob: 9px;
$header-slim-links-padding: 7px;
$header-slim-brand-padding: 12px;
$header-slim-button-v-padding: 7.5px;
$header-slim-lang-v-padding: 12px;
$header-slim-h-padding: 18px;
$header-slim-v-padding-mob: 6.5px;
// Header Slim theme light
$header-slim-theme-light-bg-color: $white !default;
$header-slim-theme-light-text-color: $primary !default;
$header-slim-theme-light-button-color: $primary !default;
$header-slim-theme-light-button-hover-color: $primary !default;

// Header Center
$header-center-bg-color: $primary !default;
$header-center-text-color: $white !default;
$header-center-max-height: 120px;
$header-center-max-height-mob: 80px;
$header-center-pad: 47px;
$header-center-pad-mob: 16px;
$header-center-h2-size: 1.75rem;
$header-center-h2-size-mob: 1.25rem;
$header-center-h2-weight: 600;
$header-center-h3-size: 0.875rem;
$header-center-icon-size: 82px;
$header-center-icon-size-mob: 48px;
$header-center-icon-margin: $v-gap * 2;
$header-center-icon-margin-mob: $v-gap;
$header-center-social-size: $v-gap * 3;
$header-center-social-distance: $v-gap * 2;
$header-center-search-size: $v-gap * 6;
$header-center-search-radius: $v-gap * 3;
$header-center-search-icon-size: $v-gap * 3;
$header-center-search-distance: $v-gap * 10;
$header-center-text-size: 0.875rem;
$header-center-button-left: 10px;
$header-nav-button-distance: 22px;
$header-nav-icon-arrow-megamenu-distance: 14px;
$header-center-small-height: 104px;
$header-center-small-mob-height: 64px;
$header-center-small-h2-size: 1.25rem;
$header-center-small-h3-size: 0.75rem;
// Header Center theme light
$header-center-theme-light-bg-color: $white !default;
$header-center-theme-light-text-color: $primary !default;

// Anchors vertical offset:
$anchor-v-offset-lg: 72px;
$anchor-v-offset: $header-center-max-height-mob + 16px;

//cards
$card-padding: $v-gap * 3;
$card-h5-size: 1.125rem;
$card-h5-line-height: 1.5rem;
$card-h5-fw: 700;
$card-h5-color: $color-text-secondary !default; // UI kit
$card-p-size: 1rem;
$card-p-size-desk: 1.125rem;
$card-p-l-h: 1.5rem;
$card-p-color: $color-text-base !default;
$card-border-color: $gray-border !default; // Missing in the UI kit but aligned to new colors
$card-small-line-height: 1px;
$card-small-line-w: 128px;
$card-small-line-color: $neutral-1-a2 !default; // Not used
$card-small-line-margin: $v-gap * 6;
$card-category-size: 0.875rem;
$card-category-l-spacing: 0.9px;
$card-category-m-bottom: $v-gap * 2;
$card-big-head-size: 1.5rem;
$card-big-head-l-h: 1.75rem;
$card-signature-size: 0.875rem;
$card-link-color: $primary !default;
$card-link-icon-size: $v-gap * 2;
$card-cat-icon-block-margin: $v-gap * 2;
$card-cat-icon-size: $v-gap * 5;
$card-simple-link-margin: $v-gap * 4;
$card-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !default;
$card-shadow-bg: $white !default;
$card-big-h5-size: 1.25rem;
$card-big-h5-l-h: 1.5rem;
$card-big-p-size: 1.125rem;
$card-big-p-l-h: 1.5rem;
$card-big-top-icon-size: $v-gap * 10;
$card-img-heading-size: 1.1111111111111112rem; // FIXME
$card-img-heading-l-h: 1.5555555555555556rem;
$special-card-img-width: 174px;
// flag icon
$flag-icon-color: $primary !default;
$flag-icon-h: $v-gap * 6;
$flag-icon-w: $v-gap * 4;
// tag
$tag-radius: 50px;

// select
$select-label-size: 0.875rem;
$select-label-color: $color-text-base !default; // UI kit
$select-label-weight: 600;
$select-button-border: 1px solid $select-label-color !default;
$select-button-padding: $v-gap !default;
$select-button-text-size: 1rem;
$select-dd-shadow: $dialog-shadow !default;
$select-dd-text-size: 1rem;
$select-dd-link-color: $primary !default;
$select-dd-link-color-active: $color-text-primary-active !default; // UI kit
$select-dd-small-separator-width: 65px;
$select-dd-small-separator-bg: $gray-border !default; // UI kit
$select-dd-icon-size: $v-gap * 3;
$select-dd-icon-flag-top: 10px;
$select-dd-pill-font-size: 0.7777777777777778rem; // FIXME
$select-dd-pill-distance: $v-gap;
$select-dd-pill-font-w: 700;
$select-dd-header-size: 0.875rem;
$select-dd-header-w: 600;
$select-dd-check-height: 30px;

//date picker
$dp-label-color: $color-text-base !default;
$dp-border-bottom: 1px solid $dp-label-color !default; // TODO Not found - need more attention
$dp-shadow: $dialog-shadow;
$dp-arrow-size: $v-gap * 2;
$dp-month-size: 0.875rem;
$dp-month-l-spacing: 0.1px;
$dp-grid-size: 0.75rem;
$dp-current-size: $v-gap * 4;

//hero
$hero-bg-color: $primary !default;
$hero-height-desk: 620px;
$hero-height-mob: 380px;
$hero-height-sm-desk: 400px;
$hero-height-sm-tab: 300px;
$hero-height-sm-mob: 230px;
$hero-text-padding: $v-gap * 3;
$hero-text-color: $white !default;
$hero-cat-size: 1rem;
$hero-heading-size: 2rem;
$hero-heding-size-desk: 2.6666666666666665rem;
$hero-p-size: 1rem;
$hero-cat-spacing: $card-category-l-spacing !default;
$hero-text-padding-desk: 120px;
$hero-dark-bg: rgba($neutral-1-a10, 0.54) !default; // Missing in the UI kit
$hero-primary-bg: rgba($primary, 0.85) !default;
$hero-negative-margin: 80px;
$hero-negative-margin-desk: 80px;
$hero-negative-bottom-padding: 240px;
$hero-negative-bottom-padding-mob: 136px;

// Carousel
$crs-margin-bottom: $v-gap * 3;
$crs-mob-live-padding: $v-gap * 2;
$crs-dots-simension: $v-gap;
$crs-dots-color: hsl(210, 83%, 77%) !default;
$crs-dots-margin-left: 38px;
$crs-dots-margin-left-desk: $v-gap * 2;
$crs-heading-h-padding: $v-gap * 3;
$crs-heading-h-size: 1.75rem;
$crs-landcape-card-padding: $v-gap * 6;
$crs-landcape-bottom: 5px;

// Gridlist **************
$grid-item-df-proportion: 66.81222707423581%;
$grid-item-df-double-proportion: 33.4061135371179%;
$grid-item-text-size: 1rem;
$grid-item-text-size-mob: 0.8rem;
$grid-item-text-color: $neutral-1 !default; // Missing in the UI kit - need more attention
$grid-item-text-overlay-bg: $neutral-1-a10 !default; // Missing in the UI kit - need more attention
$grid-list-default-gap: 2px;
$grid-list-text-gap: $v-gap;
$grid-item-sm-col: 50%;
$grid-item-lg-col: 33.333333%;
$grid-item-icon-mob-size: 24px;
$grid-item-icon-size-desk: 32px;

// list
$list-font-size: 1rem;
$list-text-padding: $v-gap * 2 0 $v-gap * 2 0;
$list-border-color: $gray-border !default; // UI kit
$list-metadata-color: $color-text-muted !default; // Missing in the UI kit but token applicable
$list-metadata-size: 12px;
$list-metadata-space: 0.5px;
$list-sub-size: 14px;
$list-sub-color: $color-text-muted !default; // UI kit

//chips
$chips-background: $color-background-muted !default; // UI kit
$chips-background-hover: $color-background-secondary-hover !default; // UI kit
$chips-label-color: $color-text-secondary !default; // UI kit
$chips-label-color-disabled: $gray-label-disabled !default; // UI kit
$chips-label-font-size: 0.875rem;
$chips-label-font-size-l: 1rem;
$chips-border: $color-border-subtle !default;

// stepper
$stepper-spacing-h: 24px;
$stepper-spacing-v: 16px;

// transfer
$transfer-border: 1px solid $neutral-1-a3 !default; // TODO Not found
$transfer-descr-size: 0.75rem;
$transfer-descr-color: $neutral-1-a8 !default; // TODO Not found
$transfer-header-line-color: $neutral-1-a3 !default; // TODO Not found
$transfer-header-line-w: 65px;
$transfer-height: 240px;

//Timeline
$timeline-width: 4px;
$timeline-padding: 12px;
$timeline-border-color: linear-gradient(0deg, $primary 0%, hsl(210, 100%, 20%) 100%) !default; // UI kit
$timeline-pin-size: $v-gap * 3;
$timeline-pin-background: hsl(210, 100%, 20%); // color-blue-20
$timeline-pin-circle-size: 48px;
$timeline-pin-circle-border: $v-gap solid $white !default;
$timeline-content-padding: 18px 0 18px 40px;
$timeline-content-padding-reverse: 18px 40px 18px 18px;

//Sections
$section-header-background-color: $primary-a7 !default; // Missing in the UI kit
$section-user-header-background-color: $primary-a1 !default; // Missing in the UI kit

// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

// Hover states breackpoint
// Define minimum dimension for over states activation
$grid-breakpoints-hover: 1025px;
// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1176px,
  xxl: 1320px,
) !default;

$badge-padding-y: 0.25em;
$badge-padding-x: 0.4em;

//Autocomplete
$autocomplete-border: 1px solid $gray-400 !default;
$autocomplete-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05) !default;
