// Headings

h1,
.h1 {
  font-size: 2.5rem;
  line-height: 3rem;
  letter-spacing: -1px;
  @include media-breakpoint-up(sm) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

h2,
.h2 {
  font-size: 2rem;
  line-height: 2.5rem;
  @include media-breakpoint-up(sm) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

h3,
.h3 {
  font-size: 1.75rem;
  line-height: 2rem;
  @include media-breakpoint-up(sm) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

h4,
.h4 {
  font-size: 1.5rem;
  line-height: 2rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.5rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.125rem;
  }
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-weight: 700;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-weight: 600;
}

// Display heading

.display-1 {
  font-size: 3rem;
  font-weight: 700;

  @include media-breakpoint-up(sm) {
    font-size: 3.5rem;
    line-height: 1.25;
  }
}

// Text elements

p,
ul,
ol,
dl {
  font-size: 1rem;
  line-height: 1.5rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.lead {
  font-size: 1.25rem;
  line-height: 2rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
  }
}

// Blockquote

blockquote,
.blockquote {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 1.5rem 0;
  margin-left: calc($spacer / 2);
  border-left: 4px solid $analogue-2;
  padding: calc($spacer / 4) $spacer;
  &.text-end {
    margin-right: calc($spacer / 2);
    border-right: 4px solid $analogue-2;
  }
  &.text-center,
  &.text-end,
  &.blockquote-simple {
    margin-left: 0;
    border-left: none;
    border-right: none;
  }
  &.text-center,
  &.blockquote-simple {
    padding: 0;
  }
  &.blockquote-simple {
    font-style: italic;
  }

  // Card version
  &.blockquote-card {
    margin-left: 0;
    padding: 16px 40px 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    .blockquote-footer {
      font-size: inherit;
      &:before {
        content: none;
      }
    }
    &.dark {
      background-color: $primary;
      border-left: none;
      color: $white;
      padding: 40px;
      .blockquote-footer {
        color: $white;
      }
    }
  }
}

.blockquote-footer {
  color: $blockquote-small-color;
  margin-top: 0;
  .bg-dark & {
    color: $blockquote-small-color-dark;
  }
}

// Global typography elements

caption,
figcaption {
  font-size: 0.875rem;
  line-height: 1rem;
}

b,
strong {
  font-weight: 700;
}

small,
.small {
  font-size: 0.875rem;
}

.x-small {
  font-size: 0.75rem;
}

// Font Family

.font-serif {
  font-family: $font-family-serif !important;
}

.font-sans-serif {
  font-family: $font-family-sans-serif !important;
}

.font-monospace {
  font-family: $font-family-monospace !important;
}

// Abbreviations

.initialism {
  font-size: 90%;
}
