#loading {
  position: fixed;
  top: 0;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  &.hidden {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease-in-out;
  }
}
