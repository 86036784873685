// Typography
* {
  font-family: "Titillium Web", sans-serif !important;
}

// Base size
html {
  font-size: 16px;
  letter-spacing: calc($letter-spacing-calculator / 16);
  @include media-breakpoint-up(lg) {
    font-size: 18px;
    letter-spacing: calc($letter-spacing-calculator / 18);
  }
}

// Headings

h1,
.h1 {
  font-size: $h1-font-size;
  line-height: $line-height-base;
  letter-spacing: calc($letter-spacing-calculator / $h1-font-size);
  margin: 0 0 map-get($spacers, 1) 0;
  @include media-breakpoint-up(sm) {
    font-size: $h1-font-size;
    letter-spacing: calc($letter-spacing-calculator / $h1-font-size);
  }
}

h2,
.h2 {
  font-size: $h2-font-size;
  line-height: $line-height-base;
  letter-spacing: calc($letter-spacing-calculator / $h2-font-size);
  margin: 0 0 map-get($spacers, 1) 0;
  @include media-breakpoint-up(sm) {
    font-size: $h2-font-size;
    letter-spacing: calc($letter-spacing-calculator / $h2-font-size);
  }
}

h3,
.h3 {
  font-size: $h3-font-size;
  line-height: $line-height-base;
  letter-spacing: calc($letter-spacing-calculator / $h3-font-size);
  margin: 0 0 map-get($spacers, 1) 0;
  @include media-breakpoint-up(sm) {
    font-size: $h3-font-size;
    letter-spacing: calc($letter-spacing-calculator / $h3-font-size);
  }
}

h4,
.h4 {
  font-size: $h4-font-size;
  line-height: $line-height-base;
  letter-spacing: calc($letter-spacing-calculator / $h4-font-size);
  margin: 0 0 map-get($spacers, 1) 0;
  @include media-breakpoint-up(sm) {
    font-size: $h4-font-size;
    letter-spacing: calc($letter-spacing-calculator / $h4-font-size);
  }
}

h5,
.h5 {
  font-size: $h5-font-size;
  line-height: $line-height-base;
  letter-spacing: calc($letter-spacing-calculator / $h5-font-size);
  margin: 0 0 map-get($spacers, 1) 0;
  @include media-breakpoint-up(sm) {
    font-size: $h5-font-size;
    letter-spacing: calc($letter-spacing-calculator / $h5-font-size);
  }
}

h6,
.h6 {
  font-size: $h6-font-size;
  line-height: $line-height-base;
  letter-spacing: calc($letter-spacing-calculator / $h6-font-size);
  margin: 0 0 map-get($spacers, 1) 0;
  @include media-breakpoint-up(sm) {
    font-size: $h6-font-size;
    letter-spacing: calc($letter-spacing-calculator / $h6-font-size);
  }
}

// Text elements

p,
ul,
ol,
dl {
  font-size: $font-size-base;
  line-height: $line-height-base;
  letter-spacing: calc($letter-spacing-calculator / $font-size-base);
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base;
    letter-spacing: calc($letter-spacing-calculator / $font-size-base);
  }
}

.lead {
  font-size: $font-size-lg;
  letter-spacing: calc($letter-spacing-calculator / $font-size-lg);
  @include media-breakpoint-up(sm) {
    font-size: $font-size-lg;
    letter-spacing: calc($letter-spacing-calculator / $font-size-lg);
  }
}

small,
.small {
  font-size: $font-size-sm;
  line-height: $line-height-base;
  letter-spacing: calc($letter-spacing-calculator / $font-size-sm);
  @include media-breakpoint-up(sm) {
    font-size: $font-size-sm;
    letter-spacing: calc($letter-spacing-calculator / $font-size-sm);
  }
}

time {
  font-size: $font-size-sm;
  line-height: $line-height-base;
  letter-spacing: calc($letter-spacing-calculator / $font-size-sm);
  @include media-breakpoint-up(sm) {
    font-size: $font-size-sm;
    letter-spacing: calc($letter-spacing-calculator / $font-size-sm);
  }
}

[role="doc-subtitle"] {
  font-size: $h5-font-size;
  color: $info;
}

em,
.em {
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $font-weight-semibold;
  letter-spacing: calc($letter-spacing-calculator / $font-size-base);
  font-style: normal;
  color: $secondary;
}

// Links

.text-link {
  text-decoration: underline;
  text-decoration-color: transparentize($primary, 0.5);

  &:hover {
    text-decoration-color: $primary;
  }
}

// Typographic length ("giustezza")

h1,
h2,
h3,
h4,
h5,
h6,
p,
dt,
dd,
small,
time {
  max-width: $max-typographic-length;
}
