// Text

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-xs: $font-size-base * 0.75;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.125;

$h1-font-size: 2rem;
$h2-font-size: 1.812rem;
$h3-font-size: 1.625rem;
$h4-font-size: 1.438rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1.125rem;

$line-height-base: 1.2;
$line-height-sm: 1;
$line-height-lg: 1.5;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-regular: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$max-typographic-length: 75ch;
$letter-spacing-calculator: 1.1rem;

// Color system

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #19191a;

$grays: (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
);

$boiler-primary: #184c85;
$dark-blue: #0e2c4d;
$charcoal: #303d4f;
$gray: #686d73;
$footer-gray: #b9c1c9;
$light-gray: #cdcdcd;
$gray-blue: #5a768a;
$boiler-secondary: #527b8b;
$boiler-success: #368054;
$boiler-danger: #d9364f;
$boiler-warn: #8f6e00;
$orange-warning: #ff9800;

$colors: (
    'white': $white,
    'black': $black,
    'dark-blue': $dark-blue,
    'charcoal': $charcoal,
    'gray': $gray,
    'light-gray': $light-gray,
    'gray-blue': $gray-blue,
    'steel-teal': $boiler-secondary,
    'success': $boiler-success,
    'danger': $boiler-danger,
    'gamboge-orange': $boiler-warn,
    'footer-gray': $footer-gray,
    'orange-warning': $orange-warning,
);

$primary: $boiler-primary;
$secondary: $boiler-secondary;
$tertiary: $dark-blue;
$success: $boiler-success;
$info: $boiler-primary;
$warning: $boiler-warn;
$danger: $boiler-danger;
$light: $gray-100;
$dark: $black;
$charcoal: $charcoal;
$footer-gray: $footer-gray;

$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'tertiary': $tertiary,
    'charcoal': $charcoal,
    'success': $boiler-success,
    'info': $info,
    'warning': $warning,
    'danger': $boiler-danger,
    'light': $light,
    'dark': $dark,
    'white': $white,
    'black': $black,
    'footer-gray': $footer-gray,
);

// Grid breakpoints

$grid-breakpoints: (
    xxs: 0,
    xs: 320px,
    sm: 360px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1280px,
);

// Grid containers

$container-max-widths: (
    xs: 272px,
    sm: 312px,
    md: 688px,
    lg: 904px,
    xl: 1140px,
    xxl: 1184px,
);

// Spacing

$spacer: 8px;
$spacers: (
    0: 0,
    1: $spacer * 1,
    2: $spacer * 2,
    3: $spacer * 3,
    4: $spacer * 4,
    5: $spacer * 5,
);

// Sizes

$base-container-size: 8px;

// Border radius

$border-radius: 0.375rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.4rem;
$border-radius-xl: 1rem;
$border-radius-2xl: 2rem;
$border-radius-pill: 50rem;

// Zindex
$zindex-max: 1100;

// Links

$link-color: $primary;
$link-decoration: none;

$header-slim-bg-color: $dark-blue;

// Toast

$toast-padding-y: map-get($spacers, 1);
$toast-padding-x: map-get($spacers, 1);
