.breadcrumbs.container-fluid {
  width: 100%;

  @media (min-width: 768px) {
      margin-right: 0;
      margin-left: 0;
  }

  .breadcrumb {
    .breadcrumb-item {
      font-size: $font-size-sm;
      color: var(--mat-app-text-color, inherit);

      &:not(.active) {
        font-weight: $font-weight-bold;
        text-decoration: underline solid var(--mat-app-text-color, inherit) 2px;
        text-underline-offset: 0.4em;
        cursor: pointer;
      }

      & + .breadcrumb-item::before {
        font-weight: $font-weight-bold;
        color: var(--mat-app-text-color, inherit);
      }
    }
  }

}
