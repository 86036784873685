html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: Titillium Web, "Helvetica Neue", sans-serif;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.vh-full {
  height: 100vh;
}

@media screen and (max-width: 576px) {
  .vh-full {
    height: calc(100vh - 80px);
  }
}

.details-form {
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;

  >.full {
    flex: 1 1 100%;
    min-width: 220px;
    max-width: 100%;
  }

  >.half {
    flex: 1 1 calc(50% - 6px);
    min-width: 220px;
    max-width: 100%;
  }

  >.third {
    flex: 1 1 calc(33.3333% - 6px);
    min-width: 220px;
    max-width: 100%;
  }

  >.quarter {
    flex: 1 1 calc(25% - 6px);
    min-width: 220px;
    max-width: 100%;
  }
}

.new-item {
  position: fixed;
  bottom: 60px;
  right: 30px;
  z-index: 2;
}

.new-itemBene {
  position: fixed;
  bottom: 2px;
  right: 30px;
  z-index: 2;
}

.upload-item {
  position: fixed;
  bottom: 2px;
  right: 100px;
  z-index: 2;
}

.upload-itemCSV {
  position: fixed;
  bottom: 60px;
  right: 170px;
  z-index: 2;
}

.mat-elevation-z0 {
  box-shadow: none !important;
}

@media screen and (max-width: 850px) {

}

@media screen and (max-width: 576px) {
  mat-table {
    mat-header-row {
      display: none;
    }

    mat-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 6px 12px;
      border-bottom: 2px solid #ccc !important;
      margin-bottom: 12px;

      mat-cell {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;

        &::before {
          content: attr(data-header) ': ';
          font-weight: bold;
          margin-right: 6px;
        }
      }
    }
  }
}

.mat-mdc-menu-panel {
  margin-bottom: 10px;
  margin-right: 20px;
}

#loading {
  position: fixed;
  top: 0;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
  }
}

.pointer-event-none {
  pointer-events: none;
}

.pointer-event-auto {
  pointer-events: auto;
}
